import { useEffect, useRef, useState } from 'react';
import Markup from 'Components/Markup';
import CloseIcon from 'Icons/close.svg?react';
import CtaButton from 'Components/CtaButton';
import useLockedBody from 'Hooks/useLockedBody';

interface ModalProps {
    title?: string;
    subTitle?: string;
    isLoading?: boolean;
    content?: string;
    children?: React.ReactNode;
    isOpened: boolean;
    onClose?: () => void;
    className?: string;
    ariaCloseButtonTranslation: string;
    backButtonTranslation: string;
    showButton?: boolean;
    forceClose?: boolean;
    onlyCloseWithButton?: boolean;
}

const Modal = ({
    title,
    subTitle,
    isLoading = false,
    content,
    children,
    isOpened,
    showButton = true,
    backButtonTranslation,
    ariaCloseButtonTranslation,
    onClose = () => false,
    forceClose = false,
    className = '',
    onlyCloseWithButton = false,
}: ModalProps) => {
    const ref = useRef<HTMLDialogElement>(null);
    const [isClosing, setIsClosing] = useState(true);
    const [, setLocked] = useLockedBody(false);

    useEffect(() => {
        if (isOpened) {
            setIsClosing(false);
            setLocked(true);
            ref.current.showModal();
        } else {
            setIsClosing(true);
        }
    }, [isOpened]);

    useEffect(() => {
        if (forceClose) {
            const dialogNode = ref.current;
            dialogNode.close();
            setLocked(false);
        }
    }, [forceClose]);

    useEffect(() => {
        const preventCloseWithEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') e.preventDefault();
        };
        const dialogNode = ref.current;
        const handleTransitionEnd = (event: TransitionEvent) => {
            event.stopPropagation();
            if (event.propertyName === 'opacity' && !isOpened) {
                dialogNode.close();
                setLocked(false);
            }
        };

        if (dialogNode) {
            dialogNode.addEventListener('transitionend', handleTransitionEnd);
            if (onlyCloseWithButton) {
                document.addEventListener('keydown', preventCloseWithEscape);
            }
        }

        return () => {
            if (dialogNode) {
                dialogNode.removeEventListener(
                    'transitionend',
                    handleTransitionEnd
                );
                if (onlyCloseWithButton) {
                    document.removeEventListener(
                        'keydown',
                        preventCloseWithEscape
                    );
                }
            }
        };
    }, [isOpened, onClose]);

    return (
        <dialog
            ref={ref}
            onCancel={e => {
                e.preventDefault();
                e.stopPropagation();
                if (!onlyCloseWithButton) {
                    onClose();
                }
            }}
            onClose={() => {
                if (!onlyCloseWithButton) onClose();
            }}
            className={`scroll-bar-gsd::-webkit-scrollbar scroll-bar-gsd::-webkit-scrollbar-thumb scroll-bar-gsd -:h-[400px] -:w-[700px] -:rounded-lg -:border -:border-solid -:border-gray-400 -:transition-all -:duration-500 -:ease-in-out -:backdrop:transition-all -:backdrop:duration-500 -:backdrop:ease-in-out -:[&:modal]:flex -:[&:modal]:flex-col -:[&:modal]:flex-wrap ${
                isClosing
                    ? '-:invisible -:opacity-0 -:backdrop:invisible -:backdrop:bg-black/0 -:backdrop:opacity-0 -:backdrop:backdrop-blur-none'
                    : '-:visible -:opacity-100 -:backdrop:visible -:backdrop:bg-black/40 -:backdrop:opacity-100 -:backdrop:backdrop-blur-sm'
            } ${className}`}
        >
            <div className="flex min-h-full flex-col p-6">
                {!isLoading && (
                    <>
                        {title && <h2>{title}</h2>}
                        {subTitle && <h4>{subTitle}</h4>}
                        {content && (
                            <Markup
                                cssClass="flex flex-shrink-0 flex-grow basis-auto flex-col flex-wrap"
                                html={content}
                            />
                        )}
                        {children}
                        {showButton && (
                            <div className="mt-6 flex justify-center">
                                <CtaButton onClick={onClose} type="button">
                                    {backButtonTranslation}
                                </CtaButton>
                            </div>
                        )}
                    </>
                )}
            </div>
            {!onlyCloseWithButton && (
                <button
                    className="absolute right-2.5 top-2.5 flex"
                    onClick={onClose}
                    type="button"
                    aria-label={ariaCloseButtonTranslation}
                >
                    <CloseIcon className="h-6 w-6 cursor-pointer" />
                </button>
            )}
        </dialog>
    );
};

export default Modal;
