import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface FormResultData {
    redirectUrl?: string;
}

export const formPageApi = createApi({
    reducerPath: 'formPageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set(
                'RequestVerificationToken',
                window.localStorage.getItem('requestVerificationToken')
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        formSubmit: builder.mutation<
            FormResultData,
            { form: HTMLFormElement; apiEndpointUrl: string }
        >({
            query: ({ form, apiEndpointUrl }) => ({
                method: 'POST',
                url: apiEndpointUrl,
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
    }),
});

export const { useFormSubmitMutation } = formPageApi;
