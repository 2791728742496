interface CtaButtonProps {
    className?: string;
    ctaType?: 'primary' | 'secondary';
    children?: React.ReactElement | string;
    [key: string]: any;
    as?: React.ElementType;
    loading?: boolean;
    autoFocus?: boolean;
}
const CtaButton = ({
    className = '',
    as,
    ctaType = 'primary',
    children,
    loading = false,
    autoFocus = false,
    ...rest
}: CtaButtonProps) => {
    const Tag = as || 'button';
    return (
        <Tag
            className={`-:block -:border -:border-solid -:px-8 -:py-2 -:text-center -:font-montserrat -:text-sm -:transition-opacity -:duration-layout  ${
                ctaType === 'secondary'
                    ? '-:border-black -:bg-white -:text-black -:hover:text-black -:focus:text-black'
                    : '-:border-cadillac -:bg-cadillac -:text-white -:hover:text-white -:focus:text-white'
            } ${className} ${loading ? '-:opacity-50' : '-:opacity-100'}`}
            autoFocus={autoFocus}
            {...rest}
        >
            {children}
        </Tag>
    );
};

export default CtaButton;
